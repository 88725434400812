import React from 'react';

import PropTypes from 'prop-types';

import { ReactComponent as LogoSmall } from '@/svg/logo.svg';

import { ReactComponent as LogoBigColourful } from '@/svg/logo-big-colourful.svg';
import { ReactComponent as LogoBigBlack } from '@/svg/logo-big-black.svg';
import { ReactComponent as LogoBigWhite } from '@/svg/logo-big-white.svg';

function Logo({ name, className }) {
	switch (name) {
        default: 
            return <LogoSmall className={className} />;

		case 'logo-small':
			return <LogoSmall className={className} />;

        case 'logo-big-colourful':
            return <LogoBigColourful className={className} />;

        case 'logo-big-black':
            return <LogoBigBlack className={className} />;

		case 'logo-big-white':
			return <LogoBigWhite className={className} />;
	}
}

export default Logo;

Logo.propTypes = {
	name: PropTypes.string.isRequired,
	className: PropTypes.string,
};

Logo.defaultProps = {
	name: null,
	className: null,
};
